/* Webfont: GT-Walsheim-Pro-Black */

@font-face {
  font-family: 'WalsheimBlack';
  src: url('../fonts/gt-walsheim/GT-Walsheim-Pro-Black.eot'); /* IE9 Compat Modes */
  src: url('../fonts/gt-walsheim/GT-Walsheim-Pro-Black.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/gt-walsheim/GT-Walsheim-Pro-Black.ttf') format('truetype'),
  url('../fonts/gt-walsheim/GT-Walsheim-Pro-BlackLato-Black.woff') format('woff'); /* Modern Browsers */
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

/* Webfont: GT-Walsheim-Pro-Bold */

@font-face {
  font-family: 'WalsheimBold';
  src: url('../fonts/gt-walsheim/GT-Walsheim-Pro-Bold.eot'); /* IE9 Compat Modes */
  src: url('../fonts/gt-walsheim/GT-Walsheim-Pro-Bold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/gt-walsheim/GT-Walsheim-Pro-Bold.ttf') format('truetype'),
  url('../fonts/gt-walsheim/GT-Walsheim-Pro-Bold.woff') format('woff'); /* Modern Browsers */
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

/* Webfont: GT-Walsheim-Pro-Light */

@font-face {
  font-family: 'WalsheimLight';
  src: url('../fonts/gt-walsheim/GT-Walsheim-Pro-Light.eot'); /* IE9 Compat Modes */
  src: url('../fonts/gt-walsheim/GT-Walsheim-Pro-Light.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/gt-walsheim/GT-Walsheim-Pro-Light.ttf') format('truetype'),
  url('../fonts/gt-walsheim/GT-Walsheim-Pro-Light.woff') format('woff'); /* Modern Browsers */
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

/* Webfont: GT-Walsheim-Pro-Medium */

@font-face {
  font-family: 'WalsheimMedium';
  src: url('../fonts/gt-walsheim/GT-Walsheim-Pro-Medium.eot'); /* IE9 Compat Modes */
  src: url('../fonts/gt-walsheim/GT-Walsheim-Pro-Medium.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/gt-walsheim/GT-Walsheim-Pro-Medium.ttf') format('truetype'),
  url('../fonts/gt-walsheim/GT-Walsheim-Pro-Medium.woff') format('woff'); /* Modern Browsers */
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

/* Webfont: GT-Walsheim-Pro-Regular */

@font-face {
  font-family: 'WalsheimRegular';
  src: url('../fonts/gt-walsheim/GT-Walsheim-Pro-Regular.eot'); /* IE9 Compat Modes */
  src: url('../fonts/gt-walsheim/GT-Walsheim-Pro-Regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/gt-walsheim/GT-Walsheim-Pro-Regular.ttf') format('truetype'),
  url('../fonts/gt-walsheim/GT-Walsheim-Pro-Regular.woff') format('woff'); /* Modern Browsers */
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

/* Webfont: GT-Walsheim-Pro-Thin */

@font-face {
  font-family: 'WalsheimThin';
  src: url('../fonts/gt-walsheim/GT-Walsheim-Pro-Thin.eot'); /* IE9 Compat Modes */
  src: url('../fonts/gt-walsheim/GT-Walsheim-Pro-Thin.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/gt-walsheim/GT-Walsheim-Pro-Thin.ttf') format('truetype'),
  url('../fonts/gt-walsheim/GT-Walsheim-Pro-Thin.woff') format('woff'); /* Modern Browsers */
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

/* Webfont: GT-Walsheim-Pro-Ultra-Bold */

@font-face {
  font-family: 'WalsheimUltraBold';
  src: url('../fonts/gt-walsheim/GT-Walsheim-Pro-Ultra-Bold.eot'); /* IE9 Compat Modes */
  src: url('../fonts/gt-walsheim/GT-Walsheim-Pro-Ultra-Bold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/gt-walsheim/GT-Walsheim-Pro-Ultra-Bold.ttf') format('truetype'),
  url('../fonts/gt-walsheim/GT-Walsheim-Pro-Ultra-Bold.woff') format('woff'); /* Modern Browsers */
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

/* Webfont: GT-Walsheim-Pro-Ultra-Light */

@font-face {
  font-family: 'WalsheimUltraLight';
  src: url('../fonts/gt-walsheim/GT-Walsheim-Pro-Ultra-Light.eot'); /* IE9 Compat Modes */
  src: url('../fonts/gt-walsheim/GT-Walsheim-Pro-Ultra-Light.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/gt-walsheim/GT-Walsheim-Pro-Ultra-Light.ttf') format('truetype'),
url('../fonts/gt-walsheim/GT-Walsheim-Pro-Ultra-Light.woff') format('woff'); /* Modern Browsers */
font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}