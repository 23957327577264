/* Webfont: Lato-Black */@font-face {
  font-family: 'LatoWebBlack';
  src: url('../fonts/lato/Lato-Black.eot'); /* IE9 Compat Modes */
  src: url('../fonts/lato/Lato-Black.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/lato/Lato-Black.woff2') format('woff2'), /* Modern Browsers */
  url('../fonts/lato/Lato-Black.woff') format('woff'), /* Modern Browsers */
  url('../fonts/lato/Lato-Black.ttf') format('truetype');
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

/* Webfont: Lato-BlackItalic */@font-face {
  font-family: 'LatoWebBlack';
  src: url('../fonts/lato/Lato-BlackItalic.eot'); /* IE9 Compat Modes */
  src: url('../fonts/lato/Lato-BlackItalic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/lato/Lato-BlackItalic.woff2') format('woff2'), /* Modern Browsers */
  url('../fonts/lato/Lato-BlackItalic.woff') format('woff'), /* Modern Browsers */
  url('../fonts/lato/Lato-BlackItalic.ttf') format('truetype');
  font-style: italic;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

/* Webfont: Lato-Bold */@font-face {
  font-family: 'LatoWebBold';
  src: url('../fonts/lato/Lato-Bold.eot'); /* IE9 Compat Modes */
  src: url('../fonts/lato/Lato-Bold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/lato/Lato-Bold.woff2') format('woff2'), /* Modern Browsers */
  url('../fonts/lato/Lato-Bold.woff') format('woff'), /* Modern Browsers */
  url('../fonts/lato/Lato-Bold.ttf') format('truetype');
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

/* Webfont: Lato-BoldItalic */@font-face {
  font-family: 'LatoWebBold';
  src: url('../fonts/lato/Lato-BoldItalic.eot'); /* IE9 Compat Modes */
  src: url('../fonts/lato/Lato-BoldItalic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/lato/Lato-BoldItalic.woff2') format('woff2'), /* Modern Browsers */
  url('../fonts/lato/Lato-BoldItalic.woff') format('woff'), /* Modern Browsers */
  url('../fonts/lato/Lato-BoldItalic.ttf') format('truetype');
  font-style: italic;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

/* Webfont: Lato-Hairline */@font-face {
  font-family: 'LatoWebHairline';
  src: url('../fonts/lato/Lato-Hairline.eot'); /* IE9 Compat Modes */
  src: url('../fonts/lato/Lato-Hairline.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/lato/Lato-Hairline.woff2') format('woff2'), /* Modern Browsers */
  url('../fonts/lato/Lato-Hairline.woff') format('woff'), /* Modern Browsers */
  url('../fonts/lato/Lato-Hairline.ttf') format('truetype');
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

/* Webfont: Lato-HairlineItalic */@font-face {
  font-family: 'LatoWebHairline';
  src: url('../fonts/lato/Lato-HairlineItalic.eot'); /* IE9 Compat Modes */
  src: url('../fonts/lato/Lato-HairlineItalic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/lato/Lato-HairlineItalic.woff2') format('woff2'), /* Modern Browsers */
  url('../fonts/lato/Lato-HairlineItalic.woff') format('woff'), /* Modern Browsers */
  url('../fonts/lato/Lato-HairlineItalic.ttf') format('truetype');
  font-style: italic;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

/* Webfont: Lato-Heavy */@font-face {
  font-family: 'LatoWebHeavy';
  src: url('../fonts/lato/Lato-Heavy.eot'); /* IE9 Compat Modes */
  src: url('../fonts/lato/Lato-Heavy.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/lato/Lato-Heavy.woff2') format('woff2'), /* Modern Browsers */
  url('../fonts/lato/Lato-Heavy.woff') format('woff'), /* Modern Browsers */
  url('../fonts/lato/Lato-Heavy.ttf') format('truetype');
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

/* Webfont: Lato-HeavyItalic */@font-face {
  font-family: 'LatoWebHeavy';
  src: url('../fonts/lato/Lato-HeavyItalic.eot'); /* IE9 Compat Modes */
  src: url('../fonts/lato/Lato-HeavyItalic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/lato/Lato-HeavyItalic.woff2') format('woff2'), /* Modern Browsers */
  url('../fonts/lato/Lato-HeavyItalic.woff') format('woff'), /* Modern Browsers */
  url('../fonts/lato/Lato-HeavyItalic.ttf') format('truetype');
  font-style: italic;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

/* Webfont: Lato-Italic */@font-face {
  font-family: 'LatoWeb';
  src: url('../fonts/lato/Lato-Italic.eot'); /* IE9 Compat Modes */
  src: url('../fonts/lato/Lato-Italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/lato/Lato-Italic.woff2') format('woff2'), /* Modern Browsers */
  url('../fonts/lato/Lato-Italic.woff') format('woff'), /* Modern Browsers */
  url('../fonts/lato/Lato-Italic.ttf') format('truetype');
  font-style: italic;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

/* Webfont: Lato-Light */@font-face {
  font-family: 'LatoWebLight';
  src: url('../fonts/lato/Lato-Light.eot'); /* IE9 Compat Modes */
  src: url('../fonts/lato/Lato-Light.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/lato/Lato-Light.woff2') format('woff2'), /* Modern Browsers */
  url('../fonts/lato/Lato-Light.woff') format('woff'), /* Modern Browsers */
  url('../fonts/lato/Lato-Light.ttf') format('truetype');
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

/* Webfont: Lato-LightItalic */@font-face {
  font-family: 'LatoWebLight';
  src: url('../fonts/lato/Lato-LightItalic.eot'); /* IE9 Compat Modes */
  src: url('../fonts/lato/Lato-LightItalic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/lato/Lato-LightItalic.woff2') format('woff2'), /* Modern Browsers */
  url('../fonts/lato/Lato-LightItalic.woff') format('woff'), /* Modern Browsers */
  url('../fonts/lato/Lato-LightItalic.ttf') format('truetype');
  font-style: italic;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

/* Webfont: Lato-Medium */@font-face {
  font-family: 'LatoWebMedium';
  src: url('../fonts/lato/Lato-Medium.eot'); /* IE9 Compat Modes */
  src: url('../fonts/lato/Lato-Medium.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/lato/Lato-Medium.woff2') format('woff2'), /* Modern Browsers */
  url('../fonts/lato/Lato-Medium.woff') format('woff'), /* Modern Browsers */
  url('../fonts/lato/Lato-Medium.ttf') format('truetype');
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

/* Webfont: Lato-MediumItalic */@font-face {
  font-family: 'LatoWebMedium';
  src: url('../fonts/lato/Lato-MediumItalic.eot'); /* IE9 Compat Modes */
  src: url('../fonts/lato/Lato-MediumItalic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/lato/Lato-MediumItalic.woff2') format('woff2'), /* Modern Browsers */
  url('../fonts/lato/Lato-MediumItalic.woff') format('woff'), /* Modern Browsers */
  url('../fonts/lato/Lato-MediumItalic.ttf') format('truetype');
  font-style: italic;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

/* Webfont: Lato-Regular */@font-face {
  font-family: 'LatoWeb';
  src: url('../fonts/lato/Lato-Regular.eot'); /* IE9 Compat Modes */
  src: url('../fonts/lato/Lato-Regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/lato/Lato-Regular.woff2') format('woff2'), /* Modern Browsers */
  url('../fonts/lato/Lato-Regular.woff') format('woff'), /* Modern Browsers */
  url('../fonts/lato/Lato-Regular.ttf') format('truetype');
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

/* Webfont: Lato-Semibold */@font-face {
  font-family: 'LatoWebSemibold';
  src: url('../fonts/lato/Lato-Semibold.eot'); /* IE9 Compat Modes */
  src: url('../fonts/lato/Lato-Semibold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/lato/Lato-Semibold.woff2') format('woff2'), /* Modern Browsers */
  url('../fonts/lato/Lato-Semibold.woff') format('woff'), /* Modern Browsers */
  url('../fonts/lato/Lato-Semibold.ttf') format('truetype');
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

/* Webfont: Lato-SemiboldItalic */@font-face {
  font-family: 'LatoWebSemibold';
  src: url('../fonts/lato/Lato-SemiboldItalic.eot'); /* IE9 Compat Modes */
  src: url('../fonts/lato/Lato-SemiboldItalic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/lato/Lato-SemiboldItalic.woff2') format('woff2'), /* Modern Browsers */
  url('../fonts/lato/Lato-SemiboldItalic.woff') format('woff'), /* Modern Browsers */
  url('../fonts/lato/Lato-SemiboldItalic.ttf') format('truetype');
  font-style: italic;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

/* Webfont: Lato-Thin */@font-face {
  font-family: 'LatoWebThin';
  src: url('../fonts/lato/Lato-Thin.eot'); /* IE9 Compat Modes */
  src: url('../fonts/lato/Lato-Thin.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/lato/Lato-Thin.woff2') format('woff2'), /* Modern Browsers */
  url('../fonts/lato/Lato-Thin.woff') format('woff'), /* Modern Browsers */
  url('../fonts/lato/Lato-Thin.ttf') format('truetype');
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

/* Webfont: Lato-ThinItalic */@font-face {
  font-family: 'LatoWebThin';
  src: url('../fonts/lato/Lato-ThinItalic.eot'); /* IE9 Compat Modes */
  src: url('../fonts/lato/Lato-ThinItalic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/lato/Lato-ThinItalic.woff2') format('woff2'), /* Modern Browsers */
  url('../fonts/lato/Lato-ThinItalic.woff') format('woff'), /* Modern Browsers */
  url('../fonts/lato/Lato-ThinItalic.ttf') format('truetype');
  font-style: italic;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

