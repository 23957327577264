@charset "UTF-8";

@font-face {
  font-family: "cr-icons";
  src:url("../fonts/icons/cr-icons.eot");
  src:url("../fonts/icons/cr-icons.eot?#iefix") format("embedded-opentype"),
  url("../fonts/icons/cr-icons.woff") format("woff"),
  url("../fonts/icons/cr-icons.ttf") format("truetype"),
  url("../fonts/icons/cr-icons.svg#cr-icons") format("svg");
  font-weight: normal;
  font-style: normal;

}

[data-icon]:before {
  font-family: "cr-icons" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "cr-icons" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-cr-user-add:before {
  content: "\62";
}
.icon-cr-user-group:before {
  content: "\63";
}
.icon-cr-user-birthday:before {
  content: "\65";
}
.icon-cr-mail-send:before {
  content: "\66";
}
.icon-cr-open-dropdown:before {
  content: "\67";
}
.icon-cr-valid-ok:before {
  content: "\68";
}
.icon-cr-user-job:before {
  content: "\69";
}
.icon-cr-location:before {
  content: "\6a";
}
.icon-cr-money:before {
  content: "\6b";
}
.icon-cr-photo:before {
  content: "\6c";
}
.icon-cr-add:before {
  content: "\6d";
}
.icon-cr-phone-new:before {
  content: "\70";
}
.icon-cr-credit-card:before {
  content: "\71";
}
.icon-cr-user-male:before {
  content: "\72";
}
.icon-cr-user-female:before {
  content: "\75";
}
.icon-cr-user-depend:before {
  content: "\77";
}
.icon-cr-building:before {
  content: "\7a";
}
.icon-cr-icon-time:before {
  content: "\6f";
}
.icon-cr-streets:before {
  content: "\76";
}
.icon-cr-calendar:before {
  content: "\79";
}
.icon-cr-house:before {
  content: "\41";
}
.icon-cr-contract:before {
  content: "\42";
}
.icon-cr-bank:before {
  content: "\78";
}
.icon-cr-user:before {
  content: "\44";
}
.icon-cr-user-state-civil:before {
  content: "\45";
}
.icon-cr-user-relation:before {
  content: "\43";
}
.icon-cr-user-gender:before {
  content: "\47";
}
.icon-cr-money-history:before {
  content: "\49";
}
.icon-cr-user-identification:before {
  content: "\4a";
}
.icon-cr-phone-old:before {
  content: "\4b";
}
.icon-cr-bag-money:before {
  content: "\4f";
}
.icon-cr-user-credit:before {
  content: "\50";
}
.icon-cr-car:before {
  content: "\52";
}
.icon-cr-user-job-situation:before {
  content: "\53";
}
.icon-cr-state:before {
  content: "\55";
}
.icon-cr-documents:before {
  content: "\56";
}
.icon-cr-valid-error:before {
  content: "\57";
}
.icon-cr-user-remove:before {
  content: "\58";
}
.icon-cr-password:before {
  content: "\59";
}
.icon-cr-mail-outlook:before {
  content: "\5a";
}
.icon-cr-mail-gmail:before {
  content: "\30";
}
.icon-cr-mail-yahoo:before {
  content: "\31";
}
.icon-cr-whatsapp:before {
  content: "\32";
}
.icon-cr-hand-ok:before {
  content: "\33";
}
.icon-cr-mobile-burger:before {
  content: "\61";
}
.icon-cr-camera:before {
  content: "\64";
}
.icon-cr-next:before {
  content: "\6e";
}
.icon-cr-previous:before {
  content: "\73";
}
.icon-cr-municipality:before {
  content: "\74";
}
.icon-cr-why-loan:before {
  content: "\46";
}
.icon-cr-next-thin:before {
  content: "\48";
}
.icon-cr-previous-thin:before {
  content: "\4c";
}
.icon-cr-loader:before {
  content: "\4d";
}
.icon-cr-social-pinterest:before {
  content: "\4e";
}
.icon-cr-social-twitter:before {
  content: "\51";
}
.icon-cr-social-facebook:before {
  content: "\54";
}
.icon-cr-icon-user-car:before {
  content: "\34";
}
.icon-cr-document:before {
  content: "\35";
}
.icon-cr-hand-mobile:before {
  content: "\36";
}
.icon-cr-social-networks:before {
  content: "\37";
}
.icon-cr-question:before {
  content: "\38";
}
.icon-cr-login:before {
  content: "\39";
}
.icon-cr-hand-arrow-right:before {
  content: "\21";
}
.icon-cr-client-door:before {
  content: "\28";
}
.icon-cr-check-form:before {
  content: "\29";
}
.icon-cr-clients-infinity:before {
  content: "\2a";
}
.icon-cr-billetes:before {
  content: "\2b";
}
.icon-cr-car-monopoly:before {
  content: "\2c";
}
.icon-cr-linkedin:before {
  content: "\2d";
}
.icon-cr-resume:before {
  content: "\2e";
}
.icon-cr-about-us:before {
  content: "\2f";
}
.icon-cr-company:before {
  content: "\3a";
}
.icon-cr-info:before {
  content: "\3b";
}
.icon-cr-logo-lendsum:before {
  content: "\3c";
}
.icon-cr-solutions:before {
  content: "\3d";
}
.icon-cr-team:before {
  content: "\3e";
}
.icon-cr-bill:before {
  content: "\3f";
}
.icon-cr-cuppon-discount:before {
  content: "\40";
}
.icon-cr-faq:before {
  content: "\5b";
}
.icon-cr-gas:before {
  content: "\5d";
}
.icon-cr-info-document:before {
  content: "\5e";
}
.icon-cr-login:before {
  content: "\5f";
}
.icon-cr-password:before {
  content: "\60";
}
.icon-cr-sms:before {
  content: "\7b";
}
.icon-cr-support-24h:before {
  content: "\7c";
}
.icon-cr-tv:before {
  content: "\7d";
}
.icon-cr-user:before {
  content: "\7e";
}
.icon-cr-watter:before {
  content: "\e000";
}
.icon-cr-paluwal:before {
  content: "\e001";
}
.icon-cr-paluwal-min:before {
  content: "\e002";
}
.icon-cr-user-add:before {
  content: "\5c";
}
.icon-cr-bulb:before {
  content: "\e003";
}
.icon-cr-logout:before {
  content: "\e004";
}
.icon-cr-up-big:before {
  content: "\e005";
}
.icon-cr-up:before {
  content: "\e006";
}
.icon-cr-up-thin:before {
  content: "\e007";
}
.icon-cr-zoom-in:before {
  content: "\e008";
}
